import React, { useEffect, useState, useRef } from 'react';
import "./saved-search-list-item.scss";
import { useNavigate } from 'react-router-dom';
import Session from '../../../models/session';

import {
  Button,
  IconButton,
  TextInput,
  Modal
  //@ts-ignore
} from '@carbon/react';

import {
  Checkmark,
  Close,
  Edit,
  TrashCan,
  MigrateAlt,
  // @ts-ignore
} from '@carbon/icons-react';

interface iProps {
  search: Session;
  removeSavedSearch: (objectId: number) => void;
  renameSavedSearch: (objectId: number, newName: string) => void;
  moveSearch: (objectId: number) => void;
}

export default function SavedSearchListItem(props: iProps) {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const [newName, setNewName] = useState(props.search.title);
  const renameInput = useRef<HTMLInputElement>(null);
  const userFilterStored = useRef<string | undefined>("Shared");
  const [movedModal, setMovedModal] = useState(false);

  userFilterStored.current = sessionStorage.getItem('userFilter')?.replace(/^"|"$/g, '');
  const microsoftName = sessionStorage.getItem('MicrosoftName')?.replace(/^"|"$/g, '');

  const rename = (evt: any) => {
    evt.stopPropagation();
    console.log('rename');
    setRenaming(true);
    setNewName(props.search.title);
  }

  useEffect(() => {
    if (renameInput.current) {
      renameInput.current.focus();
    }
  }, [renaming])

  const remove = (evt: any) => {
    evt.stopPropagation();
    props.removeSavedSearch(props.search.id);
  }

  const submitNewName = (evt: any) => {
    evt.stopPropagation();
    if (newName) {
      setRenaming(false);
      props.renameSavedSearch(props.search.id, newName);
    }
  }

  const cancelRename = (evt: any) => {
    evt.stopPropagation();
    setRenaming(false);
  }

  const goToSearch = () => {
    navigate("/properties", { replace: false, state: { search: props.search } });
  }

  const getDescription = () => {
    let descriptors: string[] = [];

    const data = props.search.data;
    const filters = data.filters;
    if (filters.propertyTypes && filters.propertyTypes.length) {
      descriptors = descriptors.concat(filters.propertyTypes);
    }

    if (filters.lotSizeMin) {
      descriptors.push(`At least ${filters.lotSizeMin} ${filters.lotSizeMin === 1 ? 'acre' : 'acres'}`);
    }

    if (filters.contactTypes && filters.contactTypes.length) {
      descriptors.push('Has contact info');
    }

    if (filters.showSavedPropertiesOnly) {
      descriptors.push('Saved properties only');
    }

    //UPDATES FROM NATALIE: FILTERS
    if (filters.customer && filters.customer.length) {
      descriptors = descriptors.concat(filters.customer.map(bc => `${bc}`));
    }
    //END

    if (filters.buildingStatus && filters.buildingStatus.length) {
      descriptors = descriptors.concat(filters.buildingStatus);
    }

    if (filters.buildingSizeMin) {
      descriptors.push(`At least ${filters.buildingSizeMin} sq ft`);
    }

    if (filters.buildingClass && filters.buildingClass.length) {
      descriptors = descriptors.concat(filters.buildingClass.map(bc => `Class ${bc}`));
    }

    if (filters.parkingMin) {
      descriptors.push(`At least ${filters.parkingMin} parking spaces`);
    }

    if (filters.amenitiesSearchText) {
      descriptors.push(`Amenites list contains "${filters.amenitiesSearchText}"`);
    }

    if (filters.closestBranch) {
      if (filters.driveTimeMax) {
        descriptors.push(`Within ${filters.driveTimeMax} minutes from ${filters.closestBranch}`);
      } else {
        descriptors.push(`Close to ${filters.closestBranch}`);
      }
    } else {
      if (filters.driveTimeMax) {
        descriptors.push(`Within ${filters.driveTimeMax} minutes from closest branch`);
      }
    }

    if (filters.crmStatus) {
      descriptors = descriptors.concat(filters.crmStatus);
    }

    if (filters.crmCompetitor) {
      descriptors.push(`Competitor is ${filters.crmCompetitor}`);
    }

    if (filters.contractsBranch) {
      descriptors.push(`Aspire branch is ${filters.contractsBranch}`);
    }

    if (filters.contractsAccountManager) {
      descriptors.push(`Account manager is ${filters.contractsAccountManager}`);
    }

    if (filters.contractsCustomer) {
      descriptors.push(`Customer is ${filters.contractsCustomer}`);
    }

    if (filters.contractsCustomer) {
      descriptors.push(`Market segment is ${filters.contractsCustomer}`);
    }

    if (data.boundary) {
      descriptors.push('Custom boundary');
    }

    if (filters.showHOA) {
      descriptors.push('HOA layer on');
    }

    const formatted = filters.MHI_number!.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;

    //updates DALTON
    if (filters.MHI_number) {
      descriptors.push(`MHI is $${formatted}`)
    }

    if (filters.selectedCities && filters.selectedCities.length > 0) {
      descriptors = descriptors.concat(filters.selectedCities)
    }

    if (filters.selectedStates && filters.selectedStates.length > 0) {
      descriptors = descriptors.concat(filters.selectedStates)
    }

    if (filters.selectedZips && filters.selectedZips.length > 0) {
      descriptors = descriptors.concat(filters.selectedZips)
    }
    // end updates DALTON
    //UPDATES FROM NATALIE: Filter as map moves applied
    if (data.filterByMapExtent) {
      descriptors.push('Filter as map moves');
    }
    //END UPDATES FROM NATALIE

    return descriptors.join("; ");
  }

  const movedModalFunct = () => {
    setMovedModal(true)

    setTimeout(() => {
      setMovedModal(false)
    }, 2000);
  }

  return (
    <div className='saved-search-list-item'
      onClick={goToSearch}
      onMouseEnter={() => { setHover(true) }}
      onMouseLeave={() => { setHover(false) }} >
      <Modal className="results-modal" open={movedModal} onRequestClose={() => setMovedModal(false)} passiveModal modalHeading=""
        preventCloseOnClickOutside={false} id="results-modal-container" onClick={(evt: any) => { evt.stopPropagation() }}>
        <div className="results-modal-div">
          Search added to your personal saved searches
        </div>
      </Modal>
      <div className="row">
        <div className="section">
          <div className="sub-section mb">
            {!renaming &&
              <span className="text-primary mb">
                {props.search.title}
              </span>
            }
            {renaming &&
              <div className="rename-container">
                <TextInput
                  className='rename-input'
                  ref={renameInput}
                  id={`rename-search-${props.search.id}-input`}
                  type='text'
                  hideLabel={true}
                  size='sm'
                  labelText={`Rename Saved Search`}
                  value={newName}
                  onChange={(evt: any) => { setNewName(evt.target.value) }}
                  onClick={(evt: any) => { evt.stopPropagation() }}
                  onKeyDown={(evt: any) => { if (evt.key === 'Enter') { submitNewName(evt) } }}
                  placeholder='Enter new name...' />
                <IconButton
                  onClick={submitNewName}
                  label="Submit"
                  kind="primary"
                  size="sm"
                >
                  <Checkmark />
                </IconButton>
                <IconButton
                  onClick={cancelRename}
                  label="Cancel"
                  kind="secondary"
                  size="sm"
                >
                  <Close />
                </IconButton>
              </div>
            }
          </div>

          <div className='sub-section text-secondary'>
            {getDescription()}
          </div>
        </div>
        {
          hover && !renaming &&
          <div className='rename-button'>
            {userFilterStored.current !== "Shared" &&
              <Button
                onClick={rename}
                kind="ghost"
                disabled={userFilterStored.current !== microsoftName}
                size="sm">
                Rename&nbsp;
                <Edit className="rename-icon" />
              </Button>
            }
            {userFilterStored.current === "Shared" &&
              <Button
                onClick={(evt: any) => { evt.stopPropagation(); props.moveSearch(props.search.id); movedModalFunct() }}
                kind="ghost"
                size="sm">
                Copy&nbsp;
                <MigrateAlt className="rename-icon" />
              </Button>
            }
            {userFilterStored.current !== "Shared" &&
              <Button
                onClick={remove}
                kind="danger--ghost"
                disabled={userFilterStored.current !== microsoftName}
                size="sm">
                Delete&nbsp;
                <TrashCan />
              </Button>
            }
          </div>
        }
      </div>
    </div>
  );
}