import React, { useEffect, useState } from 'react';
import './ZoomInfoItem.scss';
import Property from '../../../models/property';
import { roundString } from '../../../utilities';
import ToolTip from "../../shared/tool-tip/ToolTip";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import config from '../../../config';

import {
  Modal,
  //@ts-ignore
} from '@carbon/react';

import {
  Document,
  Email,
  Favorite,
  FavoriteFilled,
  Phone,
  // @ts-ignore
} from '@carbon/icons-react';

interface iProps {
  contactResult: { [key: string]: any };
  onClick?: Function;
  contactEnrich: Function | string;
  userTables: {
    searchHistory: string;
    savedSessions: string;
    notes: string;
    userMarkets: string;
    favoriteProperties: string;
    userPlatform: string;
  };
  user: __esri.PortalUser | null;
  enrichLoading: Function;
  creditsModal: Function;
  /*isFavorite: boolean;
  hasNote: boolean;
  onClick?: Function;
  onMouseEnter?: Function;
  onMouseLeave?: Function;*/
}


export default function ZoomInfoItem(props: iProps) {

  const [contactOpen, setContactOpen] = useState(false);

  //UPDATES FROM NATALIE: Add zip code, remove last characters
  //const propZipCode = props.property?.zip ? props.property.zip.split("-")[0] : '';
  //END UPDATES FROM NATALIE

  const hasEmail = props.contactResult.hasEmail || props.contactResult.hasSupplementalEmail || props.contactResult.email
  const hasPhone = props.contactResult.hasDirectPhone || props.contactResult.hasMobilePhone || props.contactResult.phone || props.contactResult.mobilePhone;
  //const hasLeasingCompanyInfo = props.property.leasingCompanyContact || props.property.leasingCompanyName;

  const iconSize = 16;

  /*const onClick = () => {
    if (props.onClick) props.onClick();
  }

  const onMouseEnter = () => {
    if (props.onMouseEnter) props.onMouseEnter();
  }

  const onMouseLeave = () => {
    if (props.onMouseLeave) props.onMouseLeave();
  }*/

  //UPDATES FROM NATALIE: nomenclature of tooltips
  const contactTooltipMessage = () => {
    if (hasEmail && hasPhone) {
      return "Has email address and phone number"
    } else if (hasEmail) {
      return "Has email address"
    } else if (hasPhone) {
      return "Has phone number"
    } else {
      return "No contact info"
    }
  }

  /*const notesMessage = () => {
    if (props.hasNote) {
      return "Has note(s)"
    } else {
      return "No notes"
    }
  }  

  const TypeMessage = () => {
    if (props.property.type_secondary) {
      return props.property.type_secondary
    } else {
      return props.property.type
    }
  } */

  function authPOST() {

    return new Promise((resolve, reject) => {
      const myHeaders = new Headers({
        "Content-Type": "application/json",
      });

      const raw = JSON.stringify({
        "username": "dsilhan@heartlandcompany.com",
        "password": "Heartland2!"
      });

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("https://api.zoominfo.com/authenticate", requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(result => {
          const bearer_token = result.jwt;
          const bearer = 'Bearer ' + bearer_token;
          resolve(bearer as any)
          return bearer as any;
        })
        .catch(error => {
          reject(error); // Reject with the error
        });
    })
  }

  function contactEnrichPOST(bearer: any, id: any) {

    return new Promise((resolve, reject) => {
      const myHeaders = new Headers({
        "Authorization": bearer,
        "Content-Type": "application/json",
      });

      const raw = JSON.stringify({
        "outputFields": [
          "id",
          "firstname",
          "middlename",
          "lastname",
          "email",
          "hascanadianemail",
          "mobilePhone",
          "phone",
          "directphonedonotcall",
          "street",
          "city",
          "region",
          "metroarea",
          "zipcode",
          "state",
          "country",
          "continent",
          "personhasmoved",
          "withineu",
          "withincalifornia",
          "withincanada",
          "lastupdateddate",
          "validdate",
          "noticeprovideddate",
          "salutation",
          "suffix",
          "jobtitle",
          "jobfunction",
          "companydivision",
          "education",
          "hashedemails",
          "picture",
          "mobilephonedonotcall",
          "externalurls",
          "companyid",
          "companyname",
          "companydescriptionlist",
          "companyphone",
          "companyfax",
          "companystreet",
          "companycity",
          "companystate",
          "companyzipcode",
          "companycountry",
          "companycontinent",
          "companylogo",
          "companysiccodes",
          "companynaicscodes",
          "contactaccuracyscore",
          "companywebsite",
          "companyrevenue",
          "companyrevenuenumeric",
          "companyemployeecount",
          "companytype",
          "companyticker",
          "companyranking",
          "isdefunct",
          "companysocialmediaurls",
          "companyprimaryindustry",
          "companyindustries",
          "companyrevenuerange",
          "companyemployeerange",
          "employmenthistory",
          "managementlevel",
          "locationcompanyid",
          "companyId"
        ],
        "matchPersonInput": [
          {
            "personId": id
          }
        ]
      });

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("https://api.zoominfo.com/enrich/contact", requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(result => {
          if (result.length === 0) {
            resolve(false); // Resolve with false
          } else {
            resolve(result)
            return result // Resolve with true
          }
        })
        .catch(error => {
          reject(error); // Reject with the error
        });
    })

  }

  // Check if the contactEnrich is a function
  function isFunction(obj: any): obj is Function {
    return typeof obj === 'function';
  }

  const contactEnrichData = (value: any) => {
    if (isFunction(props.contactEnrich)) {
      //@ts-ignore
      if (props.contactEnrich) props.contactEnrich(value);
    }
  }

  const onClick = () => {
    if (props.onClick) props.onClick();
  }

  const creditsModalFunct = () => {
    if (props.creditsModal) props.creditsModal();
  }

  const enrichLoadingFunct = () => {
    if (props.enrichLoading) props.enrichLoading();
  }

  function zoomUsageGET(bearer: any) {
    return new Promise<any>((resolve, reject) => {

      const myHeaders = new Headers({
        "Authorization": bearer
      });

      const requestOptions: RequestInit = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch("https://api.zoominfo.com/lookup/usage", requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(result => {
          if (result.length === 0) {
            reject(false); // Resolve with false
          } else {
            const remainingUniqueId = result.usage.find((item: { limitType: string; }) => item.limitType === 'uniqueIdLimit').usageRemaining;
            console.log(remainingUniqueId)
            resolve(remainingUniqueId)
          }
        })
        .catch(error => {
          reject(error); // Reject with the error
        });
    })
  }

  async function contactEnrich() {

    enrichLoadingFunct()

    const bearer = await authPOST(); // Assuming this function returns a bearer token

    const creditsLeft = await zoomUsageGET(bearer);

    if (creditsLeft > 0 && props.user) {
      // Fetch user's platform data
      executeQueryJSON(props.userTables.userPlatform, {
        where: `user_id = '${props.user.username}'`,
        outFields: ['*'],
        returnGeometry: false
      }).then(async (response) => {
        if (response.features.length > 0) {
          const credits = response.features[0].attributes.zoom_info_credits;
          if (credits > 0) {
            const data = await contactEnrichPOST(bearer, props.contactResult.id);
            onClick()
            if (data) {

              contactEnrichData(data);

              const creditsLeftAfter = await zoomUsageGET(bearer);

              enrichLoadingFunct()

              if (creditsLeftAfter < creditsLeft) {

                const featureToUpdate = response.features[0];
                const currentValue = featureToUpdate.attributes.zoom_info_credits;
                const newValue = currentValue - 1;


                // Apply the update
                featureToUpdate.attributes.zoom_info_credits = newValue;

                // Create a new FeatureLayer instance from the URL
                const featureLayer = new FeatureLayer({
                  url: props.userTables.userPlatform,
                  fields: response.fields,
                  objectIdField: 'OBJECTID',
                });

                // Apply the update using applyEdits on the feature layer instance
                featureLayer.applyEdits({
                  updateFeatures: [featureToUpdate]
                }).then((editsResult) => {
                  console.log(editsResult);
                  if (editsResult.updateFeatureResults.length > 0) {
                    console.log("Feature updated successfully");
                  } else {
                    console.error("Error updating feature");
                  }
                }).catch((error) => {
                  console.error("Error updating feature:", error);
                })

                const featureToUpdateUsed = response.features[0];
                const usedCurrentValue = featureToUpdateUsed.attributes.credits_used;
                const usedNewValue = usedCurrentValue + 1;

                // Apply the update
                featureToUpdateUsed.attributes.credits_used = usedNewValue

                // Create a new FeatureLayer instance from the URL
                const featureLayerUsed = new FeatureLayer({
                  url: props.userTables.userPlatform,
                  fields: response.fields,
                  objectIdField: 'OBJECTID',
                });

                // Apply the update using applyEdits on the feature layer instance
                featureLayerUsed.applyEdits({
                  updateFeatures: [featureToUpdateUsed]
                }).then((editsResult) => {
                  console.log(editsResult);
                  if (editsResult.updateFeatureResults.length > 0) {
                    console.log("Feature updated successfully");
                  } else {
                    console.error("Error updating feature");
                  }
                }).catch((error) => {
                  console.error("Error updating feature:", error);
                })
              }
            } else {
              enrichLoadingFunct()
              console.error("Feature not found");
            }
          } else {
            enrichLoadingFunct()
            creditsModalFunct()
            console.error("User has no credits");
          }
        }
        else { enrichLoadingFunct() }
      }).catch((error) => {
        console.error("Error fetching platform data:", error);
      });
    } else {
      enrichLoadingFunct()
      console.error("No credits left");
    }
  }


  const fullName = props.contactResult.firstName.concat(" ", props.contactResult.lastName);

  return (
    <div className="property-list-item"
      onClick={(event: any) => { contactEnrich() }}
    >
      <div className="row">
        <div className="sub-section-zoom">
          <span className="text-primary">
            {fullName}
          </span>
          {/*{
      props.isFavorite ?
        <ToolTip delay={100} direction="right" content="Saved property">
        <FavoriteFilled size={iconSize} className="is-favorite" />
        </ToolTip> :
        <Favorite size={iconSize} className="disabled-icon" />
    }
    <ToolTip delay={100} direction="right" content={notesMessage()}>
    <Document size={iconSize} className={props.hasNote ? "" : "disabled-icon"} />
  </ToolTip>   */}
        </div>
        <div className="sub-section-zoom">
          <ToolTip
            delay={100}
            direction="left"
            content={hasPhone ? "Has phone number" : "No phone number"}
          >
            <Phone size={iconSize} className={hasPhone ? "" : "disabled-icon"} />
          </ToolTip>
          <ToolTip
            delay={100}
            direction="left"
            content={hasEmail ? "Has email address" : "No email address"}
          >
            <Email size={iconSize} className={hasEmail ? "" : "disabled-icon"} />
          </ToolTip>
          <span>
            {props.contactResult.contactAccuracyScore}%
          </span>
        </div>
      </div>
      <div className='row'>
        <div className='sub-section-zoom text-secondary'>
          {props.contactResult.jobTitle}
          <br />
          {props.contactResult.company.name}
        </div>
        {/*<div className='sub-section text-tertiary'>
      <ToolTip delay={100} direction="left" content={""}>
      <span>
        {props.contactResult.company.name}
      </span>
      </ToolTip>
      <span>
        {roundString(props.property.acres, 1)} acres
</span>
    </div>*/}
      </div>
    </div>
  );
}