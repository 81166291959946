import React, { useEffect, useRef, useState, useCallback, MutableRefObject, useMemo } from "react";
import { useLocation } from 'react-router-dom';
import "./ZoomInfo.scss";
import ZoomInfoItem from "../shared/zoom-info-item/ZoomInfoItem";
import ZoomInfoDetails from "../shared/zoom-info-item/ZoomInfoDetails";
import Tooltip from "../shared/tool-tip/ToolTip";
import { useNavigate } from "react-router-dom";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import Property from "../../models/property";
import Session from "../../models/session";
//import getMap from "./SAmap";
import { useMediaQuery } from 'react-responsive'
import { DeviceSize } from '../../responsive';
import TemplateExport from "./TemplateExport"
import contactTemplate from '../../images/contact-template.png';
import { Aspire_URL_Test } from "../../SendToAspire/SendToAspire.test";
import { Aspire_URL_Dev } from "../../SendToAspire/SendToAspire.Dev";
import { Aspire_URL_Prod } from "../../SendToAspire/SendToAspire.Prod";
import { Client_ID_Test } from "../../SendToAspire/SendToAspire.test";
import { Client_ID_Dev } from "../../SendToAspire/SendToAspire.Dev";
//import { Client_ID_Prod } from "../../../../SendToAspire/SendToAspire.Prod";
import { Secret_Test } from "../../SendToAspire/SendToAspire.test";
import { Secret_Dev } from "../../SendToAspire/SendToAspire.Dev";
import { credentials } from "../../SendToAspire/APIcreds";

import {
    Column,
    Grid,
    InlineLoading,
    ToastNotification,
    Modal,
    FormItem,
    Select,
    SelectItem,
    ContentSwitcher,
    Switch,
    Accordion,
    AccordionItem,
    FileUploaderDropContainer,
    TextInput,
    IconButton,
    FileUploaderItem,
    ProgressBar,
    Button
    //@ts-ignore
} from '@carbon/react';

import { Link } from "react-router-dom";

import {
    ArrowRight,
    ArrowLeft,
    TaskView,
    Add,
    SortDescending,
    SortAscending,
    Replicate,
    CheckmarkFilled,
    Misuse,
    WarningAltFilled,
    CloudUpload
    // @ts-ignore
} from '@carbon/icons-react';

type Credentials = {
    [market: string]: {
        ALIAS: string;
        CLIENTID: string;
        SECRET: string;
    };
};

interface iProps {
    isMobile: boolean;
    uploadType: string;
    exportList?: Function;
    exportListModal: Function;
    exportListArray: Array<any>;
    filteredCredentials: Credentials | undefined;
    userTables: {
        searchHistory: string;
        savedSessions: string;
        notes: string;
        userMarkets: string;
        favoriteProperties: string;
        userPlatform: string;
    };
    user: __esri.PortalUser | null;
}

interface LocationState {
    searchCriteria?: object;
}

export default function ZoomInfo(props: iProps) {

    //const initialState = location.state || {};

    // Helper function to get initial state from sessionStorage
    const getInitialState = () => {
        const savedState = sessionStorage.getItem('combinedState');
        return savedState ? JSON.parse(savedState) : {
            searchType: 'Contact',
            searched: false,
            enriched: false,
            contactResults: [],
            metros: [],
            contactEnrichment: {},
            enrichLoading: false,
            searchLoading: false,
            creditsModal: false,
            searchErrorModal: false,
            loadMore: false,
            clickCount: 1,
            maxResults: 0,
            searchCriteria: {
                firstName: '',
                lastName: '',
                jobTitle: '',
                phone: '',
                companyName: '',
                address: '',
                city: '',
                state: '',
                street: '',
                zipCode: '',
                radius: '',
                metro: ''
            }
        };
    };

    const location = useLocation();
    const state = location.state as LocationState;
    const initialState = state?.searchCriteria || null;

    // Initialize state with values from sessionStorage or defaults
    const [searchType, setSearchType] = useState(() => getInitialState().searchType);
    const [searched, setSearched] = useState(() => initialState ? false : getInitialState().searched);
    const [enriched, setEnriched] = useState(() => initialState ? false : getInitialState().enriched);
    const [contactResults, setContactResults] = useState(() => getInitialState().contactResults);
    const [metros, setMetros] = useState(() => getInitialState().metros);
    const [contactEnrichment, setContactEnrichment] = useState(() => getInitialState().contactEnrichment);
    const [enrichLoading, setEnrichLoading] = useState(() => getInitialState().enrichLoading);
    const [searchLoading, setSearchLoading] = useState(() => getInitialState().searchLoading);
    const [creditsModal, setCreditsModal] = useState(() => getInitialState().creditsModal);
    const [searchErrorModal, setSearchErrorModal] = useState(() => getInitialState().searchErrorModal);
    const [loadMore, setLoadMore] = useState(() => getInitialState().loadMore);
    const [clickCount, setClickCount] = useState(() => getInitialState().clickCount);
    const [maxResults, setMaxResults] = useState(() => getInitialState().maxResults);
    const [searchCriteria, setSearchCriteria] = useState(() => initialState || getInitialState().searchCriteria);

    // Use useMemo to create combinedState object
    const combinedState = useMemo(() => ({
        searchType,
        searched,
        enriched,
        contactResults,
        metros,
        contactEnrichment,
        enrichLoading,
        searchLoading,
        creditsModal,
        searchErrorModal,
        loadMore,
        clickCount,
        maxResults,
        searchCriteria
    }), [
        searchType,
        searched,
        enriched,
        contactResults,
        metros,
        contactEnrichment,
        enrichLoading,
        searchLoading,
        creditsModal,
        searchErrorModal,
        loadMore,
        clickCount,
        maxResults,
        searchCriteria
    ]);

    // Use useEffect to store the combined state in sessionStorage whenever it changes
    useEffect(() => {
        const storedState = sessionStorage.getItem('combinedState');
        const parsedStoredState = storedState ? JSON.parse(storedState) : null;

        if (JSON.stringify(combinedState) !== JSON.stringify(parsedStoredState)) {
            sessionStorage.setItem('combinedState', JSON.stringify(combinedState));
        }
    }, [combinedState]);


    useEffect(() => {

        async function fieldOptions() {
            const bearer = await authPOST()
            const metros: any = await metroGET(bearer)
            setMetros(metros)
        }

        fieldOptions()

    }, [])

    const handleSearchCriteriaChange = (field: string, value: string) => {
        setSearchCriteria({ ...searchCriteria, [field]: value });
        //console.log(searchCriteria)
    };

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        if (searchLoading) {
            // Set the timer for 5 seconds (5000 milliseconds)
            timer = setTimeout(() => {
                setSearchLoading(false);
                alert('ZoomInfo search timed out');
            }, 10000);
        }
        if (enrichLoading) {
            // Set the timer for 5 seconds (5000 milliseconds)
            timer = setTimeout(() => {
                setEnrichLoading(false);
                alert('ZoomInfo search timed out');
            }, 10000);
        }

        // Cleanup the timer when the component unmounts or the state changes
        return () => clearTimeout(timer);
    }, [searchLoading, enrichLoading]);

    const onClickEnriched = () => {

        setEnriched((prevState: any) => !prevState)

    }

    const enrichLoadingFunct = () => {

        setEnrichLoading((prevState: any) => !prevState)

    }

    const creditsModalFunct = () => {

        setCreditsModal((prevState: any) => !prevState)

    }

    const enrichData = (value: any) => {

        setContactEnrichment(value)

    }

    const exportListModal = () => {
        if (props.exportListModal) props.exportListModal();
    }


    function authPOST() {

        return new Promise((resolve, reject) => {
            const myHeaders = new Headers({
                "Content-Type": "application/json",
            });

            const raw = JSON.stringify({
                "username": "dsilhan@heartlandcompany.com",
                "password": "Heartland2!"
            });

            const requestOptions: RequestInit = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch("https://api.zoominfo.com/authenticate", requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(result => {
                    const bearer_token = result.jwt;
                    const bearer = 'Bearer ' + bearer_token;
                    resolve(bearer as any)
                    return bearer as any;
                })
                .catch(error => {
                    reject(error); // Reject with the error
                });
        })
    }

    function contactPOST(bearer: any, page: number) {

        if (!loadMore) {
            page = 1
        }

        return new Promise((resolve, reject) => {
            const myHeaders = new Headers({
                "Authorization": bearer,
                "Content-Type": "application/json",
            });

            const raw = JSON.stringify({
                "firstName": searchCriteria.firstName || null,
                "lastName": searchCriteria.lastName || null,
                "jobTitle": searchCriteria.jobTitle || null,
                "phone": searchCriteria.phone ? [searchCriteria.phone] : null,
                "rpp": 100,
                "page": page,
                //"state": searchCriteria.state || null,
                "street": searchCriteria.street || null,
                "zipCode": searchCriteria.zipCode || null,
                "zipCodeRadiusMiles": searchCriteria.zipCode ? searchCriteria.radius : null,
                "metroRegion": searchCriteria.metro || null,
                "companyName": searchCriteria.companyName || null,
                //"locationSearchType": "Person"
            });

            const requestOptions: RequestInit = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch("https://api.zoominfo.com/search/contact", requestOptions)
                .then(response => {
                    if (!response.ok) {
                        setSearchLoading(false);
                        setSearchErrorModal(true)
                        setContactResults([])
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(result => {
                    if (result.data.length === 0) {
                        resolve(false); // Resolve with false
                        setContactResults([])
                    } else {
                        setMaxResults(result.maxResults)
                        resolve(result.data as any); // Resolve with true
                        return result.data
                    }
                })
                .catch(error => {
                    reject(error); // Reject with the error
                });
        })
    }

    function metroGET(bearer: any) {

        return new Promise((resolve, reject) => {
            const myHeaders = new Headers({
                "Authorization": bearer,
                "Content-Type": "application/json",
            });

            const requestOptions: RequestInit = {
                method: "POST",
                headers: myHeaders,
                //body: raw,
                redirect: "follow"
            };

            fetch("https://api.zoominfo.com/lookup/metroarea", requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(result => {
                    //console.log(result)
                    if (result.length === 0) {
                        resolve(false); // Resolve with false
                    } else {
                        resolve(result as any); // Resolve with true
                        return result
                    }
                })
                .catch(error => {
                    reject(error); // Reject with the error
                });
        })
    }

    useEffect(() => {

        if (loadMore) {

            contactSearch()

        }

    }, [clickCount])


    const loadMoreFunct = () => {
        setLoadMore((prevState: any) => !prevState)
        setClickCount((prevCount: number) => prevCount + 1);
    }

    const sortContacts = (method: any, asc: boolean) => {
        if (method === "contactAccuracyScore") {
            contactResults.sort((a: { [x: string]: any; }, b: { [x: string]: any; }) => {
                const aValue = a[method];
                const bValue = b[method];
                return asc ? aValue - bValue : bValue - aValue;
            });
        } else if (["firstName", "lastName", "jobTitle"].includes(method)) {
            contactResults.sort((a: { [x: string]: string; }, b: { [x: string]: string; }) => {
                const aValue = a[method] || "";
                const bValue = b[method] || "";
                return asc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            });
        } else if (method === 'company.name') {
            contactResults.sort((a: { company: { name: string; }; }, b: { company: { name: string; }; }) => {
                const aValue = a.company?.name || "";
                const bValue = b.company?.name || "";
                return asc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            });
        }
        setContactResults([...contactResults]); // Update state with sorted results
    };

    async function contactSearch() {

        if ((!searchCriteria.zipCode && !searchCriteria.radius) || (searchCriteria.zipCode && searchCriteria.radius)) {

            //if (((searchCriteria.firstName || searchCriteria.lastName) && (searchCriteria.zipCode || searchCriteria.metro)) || (!searchCriteria.firstName && !searchCriteria.lastName)) {

            setSearchLoading(true);
            const bearer = await authPOST();
            const data: any = await contactPOST(bearer, clickCount);

            if (data.length > 0 && !loadMore) {
                // Sort data by contactAccuracyScore in descending order
                data.sort((a: { contactAccuracyScore: number; }, b: { contactAccuracyScore: number; }) => b.contactAccuracyScore - a.contactAccuracyScore);

                setContactResults(data as any);
                setSearchLoading(false);
                setSearched(true);
                setClickCount(1);
            } else if (data.length && loadMore) {
                // Sort new data by contactAccuracyScore and concatenate with previous results
                const sortedData = [...contactResults, ...data].sort((a, b) => b.contactAccuracyScore - a.contactAccuracyScore);
                setContactResults(sortedData);
                setSearchLoading(false);
                setLoadMore((prevState: any) => !prevState);
            }

            else {
                setSearchLoading(false);
                setSearchErrorModal(true)
            }
            /*}
            else {
                alert("If First or Last Name is entered, Zip Code or Metro is required.")
            }*/
        }
        else {
            alert("If Zip Code is populated, a Zip Code Radius must also be chosen.")
        }
    }

    const searchedNOTenriched = searched && !enriched && !enrichLoading

    const finishedEnrich = searched && enriched && !enrichLoading

    const NOTsearchedORenriched = !searched && !searchLoading && !enrichLoading

    useEffect(() => {
        console.log(NOTsearchedORenriched)
    }, [searched])

    return (
        <div className="property-list">

            <Modal
                open={creditsModal}
                onRequestClose={() => setCreditsModal(false)}
                passiveModal
                preventCloseOnClickOutside
                modalHeading="Insufficient credits"
            >
                <p className="modal-notice">
                    Your account has run out of credits used to retrieve contact information from ZoomInfo.
                    Please <a href="mailto:dsilhan@heartlandcompany.com?subject=SR%20Sales%20-%20ZoomInfo%20Credits%20Request&body=Please%20grant%20my%20account%20more%20credits%20for%20contact%20research">contact us</a> to request more.
                </p>
            </Modal>

            <Modal
                open={searchErrorModal}
                onRequestClose={() => setSearchErrorModal(false)}
                passiveModal
                preventCloseOnClickOutside
                modalHeading="No results found"
            >
                <p className="modal-notice">
                    Your search has returned 0 results. Please refine or simplify your search inputs and try again.
                    <br/>
                    <br/>
                    Reminder: Searches do not consume any of your alotted credits, so don't worry about searches returning no results!
                </p>
            </Modal>
            <div className={props.isMobile ? 'property-list-container-mobile' : 'property-list-container1'}>

                {!props.isMobile &&
                    <>
                        <span className="exportList">
                            <IconButton
                                onClick={exportListModal}
                                label="View export list"
                                kind="ghost"
                                align="left"
                                disabled={props.exportListArray.length ? false : true}
                            >
                                <TaskView size={16} className="is-favorite" />
                            </IconButton>
                        </span>
                        <div className="saved-title">ZoomInfo Search</div>
                        <div className="divider"></div>
                    </>
                }

                {NOTsearchedORenriched && contactResults.length > 0 && !props.isMobile &&

                    <div className="re-search-div">
                        <IconButton className="re-search" kind="ghost" label="Back to results" align="left" wrapperClasses="re-search-wrapper" onClick={() => { setSearched(true) }}>
                            <ArrowRight className="arrow-right" />
                        </IconButton>

                    </div>

                }

                {NOTsearchedORenriched && contactResults.length > 0 && props.isMobile &&

                    <div className="re-search-div">
                        <IconButton className="re-search" kind="ghost" label="Back to results" align="left" wrapperClasses="re-search-wrapper" onClick={() => { setSearched(true) }}>
                            <ArrowRight className="arrow-right" />
                        </IconButton>

                    </div>

                }

                {NOTsearchedORenriched &&
                    <div className="search-container">
                        {/*<div className="search-by-title">
                            <div className="mode-switch">
                                <Button className={searchType === 'Contact' ? 'mode-switch-button-selected' : 'mode-switch-button'} kind='ghost' onClick={(evt: any) => { setSearchType('Contact') }}>
                                    Contact
                                </Button>
                                <Button className={searchType === 'Company' ? 'mode-switch-button-selected' : 'mode-switch-button'} kind='ghost' disabled={true} onClick={(evt: any) => { setSearchType('Company') }}>
                                    Company
                                </Button>
                </div>
                </div>*/}

                        {searchType === 'Contact' &&
                            <div className="saved-list">
                                <div className='row'>
                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="First Name"
                                        value={searchCriteria.firstName}
                                        onChange={(e: any) => handleSearchCriteriaChange('firstName', e.target.value)}
                                    />

                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="Last Name"
                                        value={searchCriteria.lastName}
                                        onChange={(e: any) => handleSearchCriteriaChange('lastName', e.target.value)}
                                    />
                                </div>
                                <br />
                                <div className='row'>
                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="Job Title"
                                        helperText="Input multiple titles by separating with 'OR'"
                                        value={searchCriteria.jobTitle}
                                        onChange={(e: any) => handleSearchCriteriaChange('jobTitle', e.target.value)}
                                    />
                                </div>
                                <br />
                                <div className='row'>
                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="Phone"
                                        value={searchCriteria.phone}
                                        onChange={(e: any) => handleSearchCriteriaChange('phone', e.target.value)}
                                    />
                                </div>
                                <br />
                                <div className='row'>
                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="Company Name"
                                        value={searchCriteria.companyName}
                                        onChange={(e: any) => handleSearchCriteriaChange('companyName', e.target.value)}
                                    />
                                </div>
                                <br />
                                <div className='row'>
                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="Street"
                                        value={searchCriteria.street}
                                        onChange={(e: any) => handleSearchCriteriaChange('street', e.target.value)}
                                    />
                                </div>
                                <br />
                                <div className='row'>
                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="Zip Code"
                                        value={searchCriteria.zipCode}
                                        onChange={(e: any) => handleSearchCriteriaChange('zipCode', e.target.value)}
                                    />

                                    {/*<TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="State"
                                        value={searchCriteria.state}
                                        onChange={(e: any) => handleSearchCriteriaChange('state', e.target.value)}
                />*/}
                                    <Select
                                        id="city-select"
                                        labelText="Zip Code Radius"
                                        onChange={(e: any) => handleSearchCriteriaChange('radius', e.target.value)}
                                        value={searchCriteria.radius}
                                    >
                                        <SelectItem
                                            value=""
                                        />
                                        {[{ name: "10", text: "10 Miles" }, { name: "25", text: "25 Miles" }, { name: "50", text: "50 Miles" }].map((radius) => (
                                            <SelectItem
                                                key={radius.name}
                                                text={radius.text}
                                                value={radius.name}
                                            />
                                        ))}
                                    </Select>
                                    <Select
                                        id="city-select"
                                        labelText="Metro Area"
                                        onChange={(e: any) => handleSearchCriteriaChange('metro', e.target.value)}
                                        value={searchCriteria.metro}
                                    >
                                        <SelectItem
                                            value=""
                                        />
                                        {metros.map((metro: { Id: any; Name: any; }) => (
                                            <SelectItem
                                                key={metro.Id}
                                                text={metro.Name}
                                                value={metro.Id}
                                            />
                                        ))}
                                    </Select>
                                </div>
                                {/*<br />
                                    <TextInput id="text-input" type="text" labelText="Zip Code" />
                                    <br />
                    <TextInput id="text-input" type="text" labelText="State" />*/}
                            </div>
                        }
                        {searchType === 'Company' &&
                            <div className="saved-list">
                                <TextInput id="text-input" type="text" labelText="Company Name" />
                                <br />
                                <TextInput id="text-input" type="text" labelText="Company Location" />
                                <br />
                                <TextInput id="text-input" type="text" labelText="Company Industry" />
                            </div>
                        }
                        <Button className="search-button" kind='primary' onClick={() => { contactSearch() }}>
                            Search
                            <ArrowRight className="arrow-right" />
                        </Button>
                    </div>
                }
                {searchLoading &&

                    <div className="loading-div">

                        <InlineLoading
                            status="active"
                            iconDescription="Loading search results"
                            description="Loading search results..."
                        />
                    </div>

                }

                {enrichLoading &&

                    <div className="loading-div">

                        <InlineLoading
                            status="active"
                            iconDescription="Loading contact information"
                            description="Loading contact information..."
                        />
                    </div>
                }
                {searchedNOTenriched && (
                    <>
                        <div className="search-results-header">
                            <Tooltip
                                delay={100}
                                direction="right"
                                content={'Back to search'}
                            >
                                <Button className="back-button" kind='ghost' onClick={() => setSearched(false)}>
                                    <ArrowLeft className="arrow-right" />
                                </Button>
                            </Tooltip>
                            <p className="results-text">
                                Showing {contactResults.length} of {maxResults} contact results
                            </p>
                            <div className="sort-container">
                                <div className="sort-div">
                                    <Accordion>
                                        <AccordionItem title={"Sort"}>
                                            {/*<ContentSwitcher>
                                                <Switch name="one" text="First section" />
                                                <Switch name="two" text="Second section" />
                                                <Switch name="three" text="Third section" />
                </ContentSwitcher>*/}

                                            {[{ text: "First Name", filter: "firstName" }, { text: "Last Name", filter: "lastName" }, { text: "Job Title", filter: "jobTitle" }, { text: "Company Name", filter: "company.name" }, { text: "Accuracy Score", filter: "contactAccuracyScore" }].map((field) => (

                                                <div className="row">

                                                    <p>
                                                        {field.text}
                                                    </p>
                                                    <div className="sort-buttons-div">


                                                        <IconButton
                                                            onClick={(e: any) => sortContacts(field.filter, false)}
                                                            label="Descending"
                                                            kind="ghost"
                                                            align="top-right"
                                                        //disabled={props.exportListArray.length ? false : true}
                                                        >
                                                            <SortDescending size={16} className="is-favorite" />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={(e: any) => sortContacts(field.filter, true)}
                                                            label="Ascending"
                                                            kind="ghost"
                                                            align="top-right"
                                                        //disabled={props.exportListArray.length ? false : true}
                                                        >
                                                            <SortAscending size={16} className="is-favorite" />
                                                        </IconButton>
                                                    </div>

                                                </div>
                                            ))}


                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                        {contactResults.map((contact: { [key: string]: any; }, index: React.Key | null | undefined) => (
                            <ZoomInfoItem
                                key={index}
                                contactResult={contact}
                                onClick={onClickEnriched}
                                contactEnrich={enrichData}
                                userTables={props.userTables}
                                user={props.user}
                                enrichLoading={enrichLoadingFunct}
                                creditsModal={creditsModalFunct}

                            />
                        ))}
                        {!loadMore && (maxResults > contactResults.length) &&

                            <div className="load-more-container">
                                {/*UPDATES FROM NATALIE: Load more button*/}
                                <Button renderIcon={Add}
                                    onClick={loadMoreFunct}
                                    kind="primary"
                                    size="lg">
                                    Load more contacts
                                </Button>
                            </div>
                        }
                    </>
                )}
                {finishedEnrich && (
                    <>
                        <ZoomInfoDetails
                            Contact={contactEnrichment}
                            onClick={onClickEnriched}
                            exportList={props.exportList}
                            filteredCredentials={props.filteredCredentials}
                        />
                    </>
                )}
            </div>
        </div>

    )
}