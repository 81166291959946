import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
//import knex from 'knex';
import Graphic from "@arcgis/core/Graphic";
import "./start.scss";
import { useNavigate } from "react-router-dom";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Property from "../../models/property";
import Session from "../../models/session";
import PropertyListItem from "../shared/property-list-item/PropertyListItem";
//import * as sql from 'mssql';

import {
  Column,
  Grid,
  InlineLoading,
  Select,
  SelectItem,
  Modal,
  ToggletipLabel,
  Toggletip,
  ToggletipButton,
  ToggletipContent,
  //@ts-ignore
} from '@carbon/react';
import { Link } from "react-router-dom";

import {
  ArrowRight,
  Information
  // @ts-ignore
} from '@carbon/icons-react';
import SavedSearchListItem from "../shared/saved-search-list-item/SavedSearchListItem";

interface iProps {
  user: __esri.PortalUser | null;
  propertyLayerUrl: string;
  savedSessionsTableUrl: string;
  favoritePropertiesTableUrl: string;
  notedProperties: Set<number>;
  savedProperties: Set<number> | undefined;
  favoriteProperty: (propertyId: number) => void;
  removeSavedSearch: (objectId: number) => void;
  renameSavedSearch: (objectId: number, newName: string) => void;
  filterSavedProperties: Function;
  moveSearch: (objectId: number) => void;
}

export default function Start(props: iProps) {

  const navigate = useNavigate();
  const propertyLayer = useRef<__esri.FeatureLayer>();
  const favoritePropertiesTable = useRef<__esri.FeatureLayer>();
  const savedSearchesTable = useRef<__esri.FeatureLayer>();

  const [propertiesLoaded, setPropertiesLoaded] = useState(false);
  const [allSavedProperties, setAllSavedProperties] = useState<Property[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);
  const [savedPropertiesCount, setSavePropertiesCount] = useState<number | null>(null);

  const [savedSearchesLoaded, setSavedSearchesLoaded] = useState(false);
  const [savedSearches, setSavedSearches] = useState<Session[]>([]);

  const [filterOn, setFilterOn] = useState<boolean>(false)
  const [userFilter, setUserFilter] = useState<string>("Shared")
  const [errorModal, setErrorModal] = useState<boolean>(false)
  const [userFilterOptions, setUserFilterOptions] = useState<string[]>([]);

  const [savedHit, setSavedHit] = useState<number>(0);

  const microsoftName = sessionStorage.getItem('MicrosoftName')?.replace(/^"|"$/g, '');

  //@ts-ignore
  const oauthData = sessionStorage.getItem('esriJSAPIOAuth') ? JSON.parse(sessionStorage.getItem('esriJSAPIOAuth'))["/"]["https://heartlandco.maps.arcgis.com/"].userId : null;
  //console.log(props.savedProperties)

  const fetchFavoriteProperties = useCallback(() => {
    setPropertiesLoaded(false)
    //console.log("memoized")
    if (!props.user?.username) return;
    const abortController = new AbortController();
    favoritePropertiesTable.current?.queryFeatures({
      where: `user_id = '${oauthData || props.user?.username}'`,
      returnGeometry: false,
      outFields: ['property_id', 'created_on', 'ms_name'],
      orderByFields: ['created_on DESC'],
      //num: 4
    }, { signal: abortController.signal }).then(favoriteProperties => {
      if (favoriteProperties.features.length > 0) {
        const propertyIds: number[] = [];
        const propertySavedOnDict: { [key: number]: number } = {};

        if (userFilter !== "Shared") {
          //console.log("filtering")
          const filteredFeatures = favoriteProperties.features.filter((feature) => {
            return feature.attributes.ms_name === userFilter;
          });

          filteredFeatures.forEach(feature => {
            const propertyId = feature.attributes['property_id'];
            propertyIds.push(propertyId);
            propertySavedOnDict[propertyId] = feature.attributes['created_on'];
          });
          props.filterSavedProperties(new Set(propertyIds))
        }
        else {
          favoriteProperties.features.forEach(feature => {
            const propertyId = feature.attributes['property_id'];
            propertyIds.push(propertyId);
            propertySavedOnDict[propertyId] = feature.attributes['created_on'];
          });
          props.filterSavedProperties(new Set(propertyIds))
        }

        //console.log(propertyIds)

        propertyLayer.current?.queryFeatures({
          where: `PropertyID IN (${propertyIds.join(',')})`,
          outFields: [
            "PropertyID",
            "Property_Name",
            "Property_Address",
            "City",
            "State",
            "Zip",
            "Property_Type_Main",
            "Land_Area__AC_",
            "RBA",
            "Rank",
            "Property_Manager_Name",
            "Property_Manager_Contact",
            "Property_Manager_Phone",
            "Leasing_Company_Name",
            "Leasing_Company_Contact",
            "Leasing_Company_Phone",
            "Owner_Name",
            "Owner_Contact",
            "Owner_Phone",
            "Amenities",
            "Number_Of_Parking_Spaces",
            "Secondary_Type",
            "GoogleStreetView",
            "GoogleSearch",
            "GoogleMaps",
            "BingStreetView",
            "Closest_Branch",
            "Customer",
            "ToBreak",
            "OpCo",
            "OBJECTID",
            "Submarket_Cluster",
            "Latitude",
            "Longitude"
          ],
          returnGeometry: false,
          //num: 4
        },
          { signal: abortController.signal }).then(response => {
            const _properties = response.features.map(feature => {
              const atts = feature.attributes;
              return {
                id: atts.PropertyID,
                name: atts.Property_Name,
                address: atts.Property_Address,
                city: atts.City,
                state: atts.State,
                zip: atts.Zip,
                type: atts.Property_Type_Main,
                acres: atts.Land_Area__AC_,
                buildingArea: atts.RBA,
                rank: atts.Rank,
                propertyManagerName: atts.Property_Manager_Name,
                propertyManagerContact: atts.Property_Manager_Contact,
                propertyManagerPhone: atts.Property_Manager_Phone,
                leasingCompanyName: atts.Leasing_Company_Name,
                leasingCompanyContact: atts.Leasing_Company_Contact,
                leasingCompanyPhone: atts.Leasing_Company_Phone,
                ownerName: atts.Owner_Name,
                ownerContact: atts.Owner_Contact,
                ownerPhone: atts.Owner_Phone,
                amenities: atts.Amenities,
                parkingSpaces: atts.Number_Of_Parking_Spaces,
                type_secondary: atts.Secondary_Type,
                googleStreetView: atts.GoogleStreetView,
                googleSearch: atts.GoogleSearch,
                googleMaps: atts.GoogleMaps,
                bingStreetView: atts.BingStreetView,
                closestBranch: atts.Closest_Branch,
                customer: atts.Customer,
                driveTime: atts.ToBreak,
                opco: atts.Sales_Company,
                objectId: atts.OBJECTID,
                submarketCluster: atts.Submarket_Cluster,
                latitude: atts.Latitude,
                longitude: atts.Longitude,
                favoriteTimestamp: propertySavedOnDict[atts.PropertyID]
              } as Property
            });

            setAllSavedProperties(_properties);

            _properties.sort((a, b) => (b.favoriteTimestamp || 0) - (a.favoriteTimestamp || 0));

            setProperties(_properties);
            setSavePropertiesCount(_properties.length);
            setPropertiesLoaded(true);
          }).catch((reason) => {
            console.error(reason);
            if (reason.name === "AbortError") return;
            setAllSavedProperties([]);
            setProperties([]);
            setSavePropertiesCount(0);
            setPropertiesLoaded(true);
          });
      } else {
        setProperties([]);
        setSavePropertiesCount(0);
        setAllSavedProperties([]);
        setPropertiesLoaded(true);
      }
    }).catch((reason) => {
      console.log(reason);
      if (reason.name === "AbortError") return;
      setAllSavedProperties([]);
      setPropertiesLoaded(true);
    });

    return () => {
      abortController.abort();
    }
  }, [props.user?.username, userFilter])

  /*const setUserFilterFirst = () => {
    const userFilterStored = sessionStorage.getItem('userFilter')?.replace(/^"|"$/g, '');
    if (props.user?.username && favoritePropertiesTable.current && propertyLayer.current && userFilterStored) {
      fetchFavoriteProperties();

      if (userFilterStored) {
        setUserFilter(userFilterStored)
      }
    }
  }*/

  useEffect(() => {
    if (props.user?.username && favoritePropertiesTable.current && propertyLayer.current) {
      fetchFavoriteProperties();
      fetchSavedSearches()
    }
  }, [userFilter]);

  const handleUserChange = (value: string) => {

    setUserFilter(value)
    sessionStorage.setItem('userFilter', JSON.stringify(value));

  }

  const fetchSavedSearches = useCallback(() => {
    setSavedSearchesLoaded(false)
    if (!props.user?.username) return;
    const abortController = new AbortController();

    savedSearchesTable.current?.queryFeatures({
      where: `user_id = '${oauthData || props.user?.username}'`,
      returnGeometry: false,
      outFields: ['session_title', 'session_json', 'created_on', 'OBJECTID', 'ms_name'],
      orderByFields: ['created_on DESC']
    }, { signal: abortController.signal }).then(featureSet => {

      let filteredFeatures: Array<any> = []

      if (userFilter !== "Shared") {
        //console.log("filtering")
        filteredFeatures = featureSet.features.filter((feature) => {
          return feature.attributes.ms_name === userFilter;
        });
      }
      else {
        featureSet.features.forEach((feature) => {
          filteredFeatures.push(feature)
        })
      }
      /*else {
        favoriteProperties.features.forEach(feature => {
          const propertyId = feature.attributes['property_id'];
          propertyIds.push(propertyId);
          propertySavedOnDict[propertyId] = feature.attributes['created_on'];
        });
        props.filterSavedProperties(new Set(propertyIds))
      }*/

      if (filteredFeatures.length > 0) {
        const searches = filteredFeatures.map(feature => {
          const atts = feature.attributes;
          return {
            id: atts.OBJECTID,
            title: atts.session_title,
            data: JSON.parse(atts.session_json),
            saveTimestamp: atts.created_on
          } as Session;
        }).reduce((acc: any[], search) => {
          if (!acc.find(s => s.title === search.title)) {
            acc.push(search);
          }
          return acc;
        }, []);
        //console.log(searches)

        setSavedSearches(searches);
        setSavedSearchesLoaded(true);
      } else {
        setSavedSearches([]);
        setSavedSearchesLoaded(true);
      }
    }).catch((reason) => {
      console.error(reason);
      setSavedSearchesLoaded(true);
    });

    return () => {
      abortController.abort();
    }
  }, [props.user?.username, userFilter]);

  useEffect(() => {
    const abortController = new AbortController();
    const userFilterStored = sessionStorage.getItem('userFilter')?.replace(/^"|"$/g, '');
    if (props.user?.username && !favoritePropertiesTable.current && !propertyLayer.current) {
      favoritePropertiesTable.current = new FeatureLayer({ url: props.favoritePropertiesTableUrl });
      propertyLayer.current = new FeatureLayer({ url: props.propertyLayerUrl });
      //fetchFavoriteProperties();
      //setUserFilterFirst();

      if (!userFilterStored || userFilterStored === "Shared") {
        fetchFavoriteProperties();
        favoritePropertiesTable.current.queryFeatureCount({
          where: `user_id = '${props.user?.username}'`
        }, { signal: abortController.signal }).then((count) => {
          setSavePropertiesCount(count);
        }).catch((err) => {
          console.log(err);
        })
      }
      else {
        setUserFilter(userFilterStored)
      }
    }
    return () => {
      abortController.abort();
    }
  }, [props.user?.username, props.favoritePropertiesTableUrl, props.propertyLayerUrl, fetchFavoriteProperties])

  useEffect(() => {
    if (props.user?.username && !savedSearchesTable.current) {
      savedSearchesTable.current = new FeatureLayer({ url: props.savedSessionsTableUrl });
      fetchSavedSearches();
    }
  }, [props.user?.username, props.savedSessionsTableUrl, fetchSavedSearches])

  const removeSavedSearch = (objectId: number) => {
    const searches = savedSearches.filter(search => search.id !== objectId);
    searches.reduce((acc: any[], search) => {
      if (!acc.find(s => s.title === search.title)) {
        acc.push(search);
      }
      return acc;
    }, []);
    setSavedSearches(searches);
    props.removeSavedSearch(objectId);
    setTimeout(() => {
      setSavedHit(prevCount => prevCount ? prevCount + 1 : 1);
    }, 2000);
  }

  const moveSearch = (objectId: number) => {
    if (props.moveSearch!) {
      props.moveSearch(objectId);
      setTimeout(() => {
        setSavedHit(prevCount => prevCount ? prevCount + 1 : 1);
      }, 2000);
    }
  }

  const favoriteProperty = (propertyId: number) => {
    if (props.favoriteProperty!) {
      props.favoriteProperty(propertyId);
      setTimeout(() => {
        setSavedHit(prevCount => prevCount ? prevCount + 1 : 1);
      }, 2000);
    }
  }

  const renameSavedSearch = (objectId: number, newName: string) => {
    const searches = [...savedSearches];
    searches.reduce((acc: any[], search) => {
      if (!acc.find(s => s.title === search.title)) {
        acc.push(search);
      }
      return acc;
    }, []);
    const search = searches.find(search => search.id === objectId);
    if (search) {
      search.title = newName;
    }
    setSavedSearches(searches);
    props.renameSavedSearch(objectId, newName);
  }

  const renameSavedProperty = (property_id: number, newName: string) => {
    const faveProperties = favoritePropertiesTable.current
    faveProperties!.queryFeatures({
      where: `property_id = ${property_id} AND ms_name = '${microsoftName}'`,
      outFields: ["*"],
    }).then((result) => {
      if (result.features.length > 0) {
        //const credits = result.features.map(feature => feature.attributes.Credits);
        // Update attributes
        //console.log(credits)
        const featureToUpdate = result.features[0];
        const currentValue = featureToUpdate.attributes.property_title;
        const newValue = newName;
        /*if (newValue == 0) {
          setCredits(false)
        }*/

        // Apply the update
        featureToUpdate.attributes.property_title = newValue;

        // Apply the update
        faveProperties!.applyEdits({
          updateFeatures: [featureToUpdate],
        }).then((editsResult) => {
          if (editsResult.updateFeatureResults.length > 0) {
            console.log("Feature updated successfully");
          } else {
            console.error("Error updating feature");
          }
        });
      } else {
        console.error("Feature not found");
      }
    });
  }

  const erroModalFunct = () => {
    setErrorModal(true)

    setTimeout(() => {
      setErrorModal(false)
    }, 2000);
  }

  function unfavoriteProperty(propertyId: number) {
    //console.log("unfaving")
    favoritePropertiesTable.current?.queryFeatures({
      where: `user_id = '${props.user?.username}' AND property_id = '${propertyId}' AND (ms_name = '${microsoftName}' OR ms_name IS NULL)`,
      outFields: ['OBJECTID', 'ms_name']
    }).then(response => {
      if (response.features.length > 0) {
        favoritePropertiesTable.current?.applyEdits({
          deleteFeatures: response.features
        })
        //fetchFavoriteProperties()
      } else {
        erroModalFunct()
      }
      setTimeout(() => {
        setSavedHit(prevCount => prevCount ? prevCount + 1 : 1);
      }, 2000);
    });
  }

  const removeProperty = useCallback((propertyId: number) => {
    const favProps = allSavedProperties.filter((property) => property.id !== propertyId);
    setAllSavedProperties(favProps);
    setProperties(favProps)
    setSavePropertiesCount(favProps.length);
    unfavoriteProperty(propertyId);
  }, [props, allSavedProperties]);

  useEffect(() => {
    if (favoritePropertiesTable.current && savedSearchesTable.current) {
      //console.log("fetching options")
      const fetchMsNames = async () => {
        const favoritePropertiesMsNames = await favoritePropertiesTable.current?.queryFeatures({
          where: `user_id = '${props.user?.username}'`,
          outFields: ['ms_name'],
          returnGeometry: false,
        });

        const savedSearchesMsNames = await savedSearchesTable.current?.queryFeatures({
          where: `user_id = '${props.user?.username}'`,
          outFields: ['ms_name'],
          returnGeometry: false,
        });

        const allMsNames = [
          ...(favoritePropertiesMsNames?.features || []).map((feature) => feature.attributes.ms_name),
          ...(savedSearchesMsNames?.features || []).map((feature) => feature.attributes.ms_name),
        ];

        const uniqueMsNames = Array.from(new Set(allMsNames)).filter((msName) => msName !== null && msName !== '');
        //console.log(uniqueMsNames)
        const userFilterOptions = ['Shared', ...uniqueMsNames];

        setUserFilterOptions(userFilterOptions);
      };

      fetchMsNames();
    }
  }, [savedSearchesLoaded, savedPropertiesCount, savedHit]);

  useEffect(() => {
    const userFilterStored = sessionStorage.getItem('userFilter')?.replace(/^"|"$/g, '');
    if (userFilterOptions.length && !userFilterOptions.includes(userFilter)) {
      setUserFilter(userFilterOptions[0]); // or some other default value
      sessionStorage.setItem('userFilter', JSON.stringify(userFilterOptions[0]));
    }
    else if (userFilter === "Shared" && (userFilterStored === "Shared" || userFilterStored === undefined)) {
      sessionStorage.setItem('userFilter', JSON.stringify("Shared"))
    }
  }, [userFilterOptions]);


  const goToProperty = (property: Property) => () => {
    navigate("/properties", { replace: false, state: { property: property } });
  }

  const firstName = props.user?.fullName ? props.user.fullName.split(" ")[0] : '';

  const testArray = ["Shared", "Dalton Silhan", "Brian Williams", "Taylor Kerton", "Richard Harper"]

  /*const db = knex({
    client: 'mssql',
    connection: {
      user: 'Dsilhan',
      password: 'Silhan12!',
      server: 'heartlandanalytics.database.windows.net',
      database: 'AnalyticMaster',
      options: {
        encrypt: true,
        trustServerCertificate: true,
      },
    },
  });
  
  async function getUtilizationScore(username: string | undefined) {
    try {
      const result = await db('SRSales.LoginsAzure')
        .select(
          db.raw('DATEDIFF(day, "1900-01-01", Date) / 7 AS Week'),
          db.raw('COUNT(*) AS Count')
        )
        .where('Username', username)
        .groupBy(db.raw('DATEDIFF(day, "1900-01-01", Date)'));
      
      return result; // This will return an array of results
    } catch (err) {
      console.error('SQL error', err);
    } finally {
      await db.destroy(); // Close the connection pool
    }
  }
  
  useEffect(() => {
    getUtilizationScore(microsoftName).then((result) => {
      console.log(result);
    });
  }, [microsoftName]);*/

  return (
    <div className="start">
      <Modal className="results-modal" open={errorModal} onRequestClose={() => setErrorModal(false)} passiveModal modalHeading="Error"
        preventCloseOnClickOutside={false} id="results-modal-container">
        <div className="results-modal-div">
          You cannot delete another BDMs' saved properties
        </div>
      </Modal>
      <Grid>
        <Column sm={4} md={8} lg={16}>
          <div className="greeting">
            Hi, {microsoftName?.split(" ")[0] || firstName}
          </div>
        </Column>
      </Grid>
      <Grid >
        <Column sm={4} md={8} lg={8}>
          <div className="get-started">
            Get started prospecting, view your sales reporting dashboard, or pick up where you left off below.
          </div>
        </Column>
        <Column sm={4} md={8} lg={8}>
          <Grid>
            <Column sm={4} md={4} lg={4}>
              <Link to="/properties" className="no-text-decoration">
                <div className="link-card">
                  <div className="link-card-title">
                    Properties
                  </div>
                  <div className="link-card-description">
                    Identify valuable properties using the new map and filtering tools.
                  </div>
                  <ArrowRight className='arrow' size={32} />
                </div>
              </Link>
            </Column>
            <Column sm={4} md={4} lg={4}>
              <Link to="/new-sales" className="no-text-decoration">
                <div className="link-card">
                  <div className="link-card-title">
                    New Sales Dashboard
                  </div>
                  <div className="link-card-description">
                    Track key sales metrics for your branch team and properties.
                  </div>
                  <ArrowRight className='arrow' size={32} />
                </div>
              </Link>
            </Column>
          </Grid>
        </Column>
      </Grid>
      <div className="divider"></div>
      {userFilterOptions &&
        <div className="user-select-div">
          <Select
            id="user-select"
            className="user-select"
            placeholder="Select Value"
            disabled={!propertiesLoaded}
            labelText="User Select"
            value={userFilter}
            onChange={(e: any) => handleUserChange(e.target.value)}
          >
            {userFilterOptions?.map((branch: any) => {
              return (
                <SelectItem
                  key={branch}
                  value={branch}
                  text={branch}
                />
              );
            })}
          </Select>
          <div className="toggletip-div">
            {/*<ToggletipLabel className="potential">Potential</ToggletipLabel>*/}
            <Toggletip align="right">
              <ToggletipButton label="Show information">
                <Information className="info-icon" size={16} />
              </ToggletipButton>
              <ToggletipContent>
                <p>
                  Select by user to view saved properties/searches by user. Select "Shared" to see all from users in your opco/region, or copy shared items to your personal account! You cannot delete/rename items from other users.
                </p>
              </ToggletipContent>
            </Toggletip>
          </div>
        </div>
      }
      <Grid >
        {savedSearches && /*props.savedProperties &&*/

          <Column sm={4} md={8} lg={8}>
            <div className="saved-title">Recently saved searches</div>
            {userFilter === "Shared" && savedSearches.length > 0 &&
              <p className="saved-note"> *View saved searches from all users in the shared account or copy searches to your personal account </p>
            }
            {userFilter === microsoftName && savedSearches.length > 0 &&
              <p className="saved-note"> *View, rename, or delete saved searches in your personal account </p>
            }
            <div className="saved-list">
              {savedSearchesLoaded && savedSearches.slice(0, 4).map((search, index) =>
                <SavedSearchListItem
                  key={index}
                  search={search}
                  removeSavedSearch={removeSavedSearch}
                  renameSavedSearch={renameSavedSearch}
                  moveSearch={moveSearch}
                />
              )}
              {!savedSearchesLoaded &&
                <InlineLoading
                  status="active"
                  iconDescription="Active Loading Indicator"
                  description="Loading saved searches..."
                />
              }
            </div>
            {savedSearchesLoaded && savedSearches.length > 0 &&
              <Link to="/start/saved-searches" className="view-all cds--link">
                View all {savedSearches.length} saved searches
              </Link>
            }
            {savedSearchesLoaded && savedSearches.length === 0 &&
              <p className="saved-note-large">
                No saved searches. Begin prospecting to save searches or copy saved searches from "Shared".
              </p>
            }
          </Column>
        }

        {props.savedProperties &&

          <Column sm={4} md={8} lg={8}>
            <div className="saved-title">
              Recently saved properties
            </div>
            {userFilter === "Shared" && savedPropertiesCount! > 0 &&
              <p className="saved-note"> *View saved properties from all users in the shared account or copy properties to your personal account </p>
            }
            {userFilter === microsoftName && savedPropertiesCount! > 0 &&
              <p className="saved-note"> *View, rename, or delete saved properties in your personal account </p>
            }

            <div className="saved-list">
              {propertiesLoaded && properties.slice(0, 4).map((property, index) =>
                <PropertyListItem
                  key={index}
                  property={property}
                  isFavorite={true}
                  hasNote={props.notedProperties.has(property.id)}
                  onClick={goToProperty(property)}
                  removeProperty={removeProperty}
                  renameSavedProperty={renameSavedProperty}
                  favoriteProperty={favoriteProperty}
                />
              )}
              {!propertiesLoaded &&
                <InlineLoading
                  status="active"
                  iconDescription="Active Loading Indicator"
                  description="Loading saved properties..."
                />
              }
            </div>
            {propertiesLoaded && savedPropertiesCount! > 0 &&
              <Link to="/start/saved-properties" className="view-all cds--link">
                View all {savedPropertiesCount} saved properties
              </Link>
            }
            {propertiesLoaded && savedPropertiesCount! === 0 &&
              <p className="saved-note-large">
                No saved properties. Begin prospecting to save properties or copy saved properties from "Shared".
              </p>
            }
          </Column>
        }
      </Grid>
    </div >
  );
}
