import React, { useEffect, useState, useRef } from 'react';
import './property-list-item.scss';
import Property from '../../../models/property';
import { roundString } from '../../../utilities';
import ToolTip from "../../shared/tool-tip/ToolTip";
import config from '../../../config';

import {
  Button,
  IconButton,
  TextInput,
  Modal
  //@ts-ignore
} from '@carbon/react';

import {
  Document,
  Enterprise,
  Favorite,
  FavoriteFilled,
  User,
  Checkmark,
  Close,
  Edit,
  TrashCan,
  MigrateAlt
  // @ts-ignore
} from '@carbon/icons-react';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';

interface iProps {
  property: Property;
  isFavorite: boolean;
  hasNote: boolean;
  onClick?: Function;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  removeProperty: (propertyId: number) => void;
  renameSavedProperty: Function | null;
  favoriteProperty: (propertyId: number) => void;
}


export default function PropertyListItem(props: iProps) {

  const [renaming, setRenaming] = useState(false);
  const [newName, setNewName] = useState<any>(null);
  const renameInput = useRef<HTMLInputElement>(null);
  const [hover, setHover] = useState(false);

  const [movedModal, setMovedModal] = useState<boolean>(false)
  const userFilterStored = useRef<string | undefined>("Shared");

  userFilterStored.current = sessionStorage.getItem('userFilter')?.replace(/^"|"$/g, '');
  const microsoftName = sessionStorage.getItem('MicrosoftName')?.replace(/^"|"$/g, '');

  //UPDATES FROM NATALIE: Add zip code, remove last characters
  const propZipCode = props.property?.zip ? props.property.zip.split("-")[0] : '';
  //END UPDATES FROM NATALIE

  const hasManagerInfo = props.property.propertyManagerContact || props.property.propertyManagerName;
  const hasOwnerInfo = props.property.ownerContact || props.property.ownerName;
  const hasLeasingCompanyInfo = props.property.leasingCompanyContact || props.property.leasingCompanyName;

  const [favoriteByUser, setfavoriteByUser] = useState<boolean>(false);

  const iconSize = 16;

  const onClick = () => {
    if (props.onClick) props.onClick();
  }

  const onMouseEnter = () => {
    if (props.onMouseEnter) props.onMouseEnter()
    setHover(true)
  }

  const onMouseLeave = () => {
    if (props.onMouseLeave) props.onMouseLeave();
    setHover(false)
  }

  useEffect(() => {
    if (props.renameSavedProperty) {
      const faveProperties = new FeatureLayer({ url: "https://services1.arcgis.com/dqIGQgErV3nGqtSd/arcgis/rest/services/SmartReach_User_Tables/FeatureServer/4" });
  
      const whereClause = `property_id = ${props.property.id} AND (ms_name = '${microsoftName}' OR ms_name IS NULL OR (ms_name IS NOT NULL AND ms_name <> '${microsoftName}'))`;
  
      faveProperties!.queryFeatures({
        where: whereClause,
        outFields: ["*"],
      }).then((result) => {
        if (result.features.length > 0) {
          let selectedFeature = null;
  
          // First, check for a feature with ms_name equal to microsoftName
          selectedFeature = result.features.find(feature => feature.attributes.ms_name === microsoftName);
  
          // If not found, check for any feature with a non-null ms_name
          if (!selectedFeature) {
            selectedFeature = result.features.find(feature => feature.attributes.ms_name !== null);
          }
  
          // If still not found, fallback to the first feature (which should have ms_name as NULL)
          if (!selectedFeature) {
            selectedFeature = result.features[0]; // This should be safe as there are features
          }
  
          // Now use the selected feature to set the new name
          const currentValue = selectedFeature.attributes.property_title;
          setNewName(
            currentValue?.trim() || props.property.name?.trim() || props.property.address
          );
        } else {
          console.error("Feature not found");
        }
      });
    }
  }, [props.removeProperty, props.renameSavedProperty, props.favoriteProperty])

  //UPDATES FROM NATALIE: nomenclature of tooltips
  const ownerTooltipMessage = () => {
    if (hasOwnerInfo && hasLeasingCompanyInfo) {
      return "Has owner and leasing company info"
    } else if (hasOwnerInfo) {
      return "Has owner info"
    } else if (hasLeasingCompanyInfo) {
      return "Has leasing company info"
    } else {
      return "No owner or leasing company info"
    }
  }

  //UPDATES FROM NATALIE: Notes icon tooltip logic
  const notesMessage = () => {
    if (props.hasNote) {
      return "Has note(s)"
    } else {
      return "No notes"
    }
  }
  //END UPDATES FROM NATALIE

  //UPDATES FROM NATALIE: Secondary type tooltip logic
  const TypeMessage = () => {
    if (props.property.type_secondary) {
      return props.property.type_secondary
    } else {
      return props.property.type
    }
  }

  const rename = (evt: any) => {
    evt.stopPropagation();
    console.log('rename');
    setRenaming(true);
  }

  useEffect(() => {
    if (renameInput.current) {
      renameInput.current.focus();
    }
  }, [renaming])

  const submitNewName = (evt: any) => {
    evt.stopPropagation();
    if (newName) {
      setRenaming(false);
      if (props.renameSavedProperty) {
        props.renameSavedProperty(props.property.id, newName);
      }
    }
  }

  const cancelRename = (evt: any) => {
    evt.stopPropagation();
    setRenaming(false);
  }

  const remove = (evt: any) => {
    evt.stopPropagation();
    if (props.removeProperty)
      props.removeProperty(props.property.id);
    setNewName(null)

  }

  const movedModalFunct = () => {
    setMovedModal(true)

    setTimeout(() => {
      setMovedModal(false)
    }, 2000);
  }

  /*useEffect(() => {

    const savedPropertiesTable = new FeatureLayer({ url: config.userTables.favoriteProperties })

    savedPropertiesTable.queryFeatureCount({
      where: `property_id = '${props.property.id}' AND ms_name = '${microsoftName}'`
    }).then(count => {

      if (count === 0) {
        setfavoriteByUser(false)
      }
      else {
        setfavoriteByUser(true)
      }

    });
    
  }, [])*/

  return (
    <div className={`property-list-item ${props.onClick ? 'pointer' : ''} ${props.onMouseEnter ? 'hover-effect' : ''}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <Modal className="results-modal" open={movedModal} onRequestClose={() => setMovedModal(false)} passiveModal modalHeading=""
        preventCloseOnClickOutside={false} id="results-modal-container" onClick={(evt: any) => { evt.stopPropagation() }}>
        <div className="results-modal-div">
          Property added to your personal saved properties
        </div>
      </Modal>
      <div className="row">
        {props.renameSavedProperty &&
          <div className="section">
            <div className="sub-section mb">
              {!renaming &&
                <span className="text-primary mb">
                  {newName?.trim() || props.property.name?.trim() || props.property.address}
                </span>
              }

              {renaming &&
                <div className="rename-container">
                  <TextInput
                    className='rename-input'
                    ref={renameInput}
                    id={`rename-search-${props.property.id}-input`}
                    type='text'
                    hideLabel={true}
                    size='sm'
                    labelText={`Rename Saved Property`}
                    value={newName}
                    onChange={(evt: any) => {
                      if (evt.target.value.length <= 50) { // Example character limit
                        setNewName(evt.target.value);
                      }
                    }}
                    onClick={(evt: any) => { evt.stopPropagation() }}
                    onKeyDown={(evt: any) => { if (evt.key === 'Enter') { submitNewName(evt) } }}
                    maxLength={50} // Set the maximum number of characters
                    placeholder='Enter new name...'
                  />
                  <IconButton
                    onClick={submitNewName}
                    label="Submit"
                    kind="primary"
                    size="sm"
                  >
                    <Checkmark />
                  </IconButton>
                  <IconButton
                    onClick={cancelRename}
                    label="Cancel"
                    kind="secondary"
                    size="sm"
                  >
                    <Close />
                  </IconButton>
                </div>
              }
              {!renaming &&
                <span>
                  {
                    props.isFavorite ?
                      //UPDATES FROM NATALIE: Add tooltip to heart icon
                      <ToolTip delay={100} direction="right" content="Saved property">
                        <FavoriteFilled size={iconSize} className="is-favorite" />
                      </ToolTip> :
                      <Favorite size={iconSize} className="disabled-icon" />
                  }
                  {/*Add tooltip to notes icon*/}
                  <ToolTip delay={100} direction="right" content={notesMessage()}>
                    <Document size={iconSize} className={props.hasNote ? "" : "disabled-icon"} />
                  </ToolTip>
                  {/*END UPDATES FROM NATALIE*/}
                </span>
              }
            </div>

            {/*<div className='sub-section text-secondary'>
            {getDescription()}
          </div>*/}
          </div>
        }
        {!props.renameSavedProperty &&
          <div className="sub-section">
            <span className="text-primary" >
              {props.property.name ? props.property.name : props.property.address}
            </span>
            {
              props.isFavorite ?
                //UPDATES FROM NATALIE: Add tooltip to heart icon
                <ToolTip delay={100} direction="right" content="Saved property">
                  <FavoriteFilled size={iconSize} className="is-favorite" />
                </ToolTip> :
                <Favorite size={iconSize} className="disabled-icon" />
            }
            {/*Add tooltip to notes icon*/}
            <ToolTip delay={100} direction="right" content={notesMessage()}>
              <Document size={iconSize} className={props.hasNote ? "" : "disabled-icon"} />
            </ToolTip>
            {/*END UPDATES FROM NATALIE*/}
          </div>
        }
        {
          hover && !renaming && props.renameSavedProperty &&
          <div className='action-container'>
            <div className='rename-button'>
              {userFilterStored.current !== "Shared" &&
                <Button
                  onClick={rename}
                  kind="ghost"
                  disabled={userFilterStored.current !== microsoftName}
                  size="sm">
                  Rename&nbsp;
                  <Edit className="rename-icon" />
                </Button>
              }
              {userFilterStored.current === "Shared" &&
                <Button
                  onClick={(evt: any) => { evt.stopPropagation(); props.favoriteProperty(props.property.id); movedModalFunct() }}
                  kind="ghost"
                  size="sm">
                  Copy&nbsp;
                  <MigrateAlt className="rename-icon" />
                </Button>
              }
              {userFilterStored.current !== "Shared" &&
                <Button
                  onClick={remove}
                  kind="danger--ghost"
                  disabled={userFilterStored.current !== microsoftName}
                  size="sm">
                  Delete&nbsp;
                  <TrashCan />
                </Button>
              }
            </div>
          </div>
        }
        {(!hover || !window.location.href.includes('start')) &&
          <div className="sub-section">
            <ToolTip
              delay={100}
              direction="left"
              //UPDATES FROM NATALIE: Updated nomenclature of tooltips
              content={hasManagerInfo ? "Has property manager info" : "No property manager info"}
            //END UPDATES FROM NATALIE
            >
              <User size={iconSize} className={hasManagerInfo ? "" : "disabled-icon"} />
            </ToolTip>
            <ToolTip
              delay={100}
              direction="left"
              content={ownerTooltipMessage()}
            >
              <Enterprise size={iconSize} className={hasOwnerInfo || hasLeasingCompanyInfo ? "" : "disabled-icon"} />
            </ToolTip>
            <span>
              {props.property.rank}%
            </span>
          </div>
        }
      </div>
      <div className='row'>
        <div className='sub-section text-secondary'>
          {/*UPDATES FROM NATALIE: remove duplicate address and add geog area*/}
          {/*props.property.address*/}
          {props.property.city}, {props.property.state} {propZipCode}
          {/*END UPDATES FROM NATALIE*/}
        </div>
        {(!hover || !window.location.href.includes('start')) &&
          <div className='sub-section text-tertiary'>
            {/*UPDATES FROM NATALIE: Show secondary type on property type tooltip*/}
            <ToolTip delay={100} direction="left" content={TypeMessage()}>
              <span>
                {props.property.type}
              </span>
            </ToolTip>
            {/*UPDATES FROM NATALIE*/}
            <span>
              {roundString(props.property.acres, 1)} acres
            </span>
          </div>
        }
      </div>
    </div>
  );
}