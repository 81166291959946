import React, { useEffect, useRef, useState } from "react";
import "./saved-searches.scss";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import SavedSearchListItem from "../shared/saved-search-list-item/SavedSearchListItem";
import Session from "../../models/session";
import { Link, useNavigate } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  InlineLoading,
  Layer,
  Search,
  Dropdown,
  Select,
  SelectItem
  //@ts-ignore
} from '@carbon/react';

import {
  Add,
  //@ts-ignore
} from '@carbon/icons-react';

interface iProps {
  user: __esri.PortalUser | null;
  savedSearchesTableUrl: string;
  removeSavedSearch: (objectId: number) => void;
  renameSavedSearch: (objectId: number, newName: string) => void;
  moveSearch: (objectId: number) => void;
}

const sortOptions = ["Recently added"];
export default function SavedSearches(props: iProps) {
  const navigate = useNavigate();
  const savedSearchesTable = useRef<__esri.FeatureLayer>();
  const [savedSearchesLoaded, setSavedSearchesLoaded] = useState(false);
  const [allSavedSearches, setAllSavedSearches] = useState<Session[]>([]);
  const [displaySavedSearches, setDisplaySavedSearches] = useState<Session[]>([]);
  const [sortOption, setSortOption] = useState("Recently added");
  const [filterText, setFilterText] = useState("");
  const userFilterStored = useRef<string | undefined>("Shared");
  const [userFilter, setUserFilter] = useState<string>("Shared")
  const [userFilterOptions, setUserFilterOptions] = useState<string[]>([]);
  const [savedHit, setSavedHit] = useState<number>(0);

  useEffect(() => {
    //console.log("effected")
    userFilterStored.current = sessionStorage.getItem('userFilter')?.replace(/^"|"$/g, '');
    const abortController = new AbortController();
    if (props.user?.username/* && !savedSearchesTable.current*/) {
      setSavedSearchesLoaded(false)
      savedSearchesTable.current = new FeatureLayer({ url: props.savedSearchesTableUrl });

      savedSearchesTable.current?.queryFeatures({
        where: `user_id = '${props.user?.username}'`,
        returnGeometry: false,
        outFields: ['session_title', 'session_json', 'created_on', 'OBJECTID', 'ms_name'],
        orderByFields: ['created_on DESC']
      }, { signal: abortController.signal }).then(featureSet => {
        let filteredFeatures: Array<any> = []
        //console.log(userFilterStored)
        if (userFilterStored.current !== "Shared") {
          filteredFeatures = featureSet.features.filter((feature) => {
            return feature.attributes.ms_name === userFilterStored.current;
          })
        }
        //console.log(filteredFeatures)
        if (filteredFeatures.length > 0 || featureSet.features.length > 0) {
          const searches = (filteredFeatures?.length > 0 ? filteredFeatures : featureSet.features).map(feature => {
            const atts = feature.attributes;
            return {
              id: atts.OBJECTID,
              title: atts.session_title,
              data: JSON.parse(atts.session_json),
              saveTimestamp: atts.created_on
            } as Session;
          });
          const uniqueSearches = searches.reduce((acc: any[], search) => {
            if (!acc.find(s => s.title === search.title)) {
              acc.push(search);
            }
            return acc;
          }, []);

          setAllSavedSearches(uniqueSearches);
          setSavedSearchesLoaded(true);
        } else {
          setAllSavedSearches([]);
          setSavedSearchesLoaded(true);
        }
      }).catch((reason) => {
        console.error(reason);
        if (reason.name === "AbortError") return;
        setSavedSearchesLoaded(true);
      });
    }

    return () => {
      abortController.abort();
    }
  }, [props.user?.username, props.savedSearchesTableUrl, userFilterStored, userFilter])

  const filterSavedSearches = () => {
    if (allSavedSearches.length > 0) {
      let _searches = [...allSavedSearches];
      if (filterText) {
        _searches = _searches.filter(search =>
          search.title?.toLowerCase().includes(filterText)
        );
      }

      if (sortOption === "Recently added") {
        _searches.sort((a, b) => (b.saveTimestamp || 0) - (a.saveTimestamp || 0));
      }

      setDisplaySavedSearches(_searches)
    } else {
      setDisplaySavedSearches([]);
    }
  }
  useEffect(filterSavedSearches, [sortOption, filterText, allSavedSearches]);

  const onFilterChange = (evt: any) => {
    setFilterText(evt.target.value.toLowerCase());
  }

  const onSortOptionChange = (evt: any) => {
    setSortOption(evt.selectedItem);
  }

  const removeSavedSearch = (objectId: number) => {
    const searches = allSavedSearches.filter(search => search.id !== objectId);
    setAllSavedSearches(searches);
    props.removeSavedSearch(objectId);
    setTimeout(() => {
      setSavedHit(prevCount => prevCount ? prevCount + 1 : 1);
    }, 2000);
  }

  const moveSearch = (objectId: number) => {
    if (props.moveSearch!) {
      props.moveSearch(objectId);
      setTimeout(() => {
        setSavedHit(prevCount => prevCount ? prevCount + 1 : 1);
      }, 2000);
    }
  }

  const renameSavedSearch = (objectId: number, newName: string) => {
    const searches = [...allSavedSearches];
    const search = searches.find(search => search.id === objectId);
    if (search) {
      search.title = newName;
    }
    setAllSavedSearches(searches);
    props.renameSavedSearch(objectId, newName);
  }

  useEffect(() => {
    const userFilterStored = sessionStorage.getItem('userFilter')?.replace(/^"|"$/g, '');
    if (userFilterStored) {
      setUserFilter(userFilterStored)
    }
  }, []);

  const handleUserChange = (value: string) => {

    setUserFilter(value)
    sessionStorage.setItem('userFilter', JSON.stringify(value));

  }

  useEffect(() => {
    if (savedSearchesTable.current) {
      //console.log("fetching options")
      const fetchMsNames = async () => {
        /*const favoritePropertiesMsNames = await favoritePropertiesTable.current?.queryFeatures({
          where: `user_id = '${props.user?.username}'`,
          outFields: ['ms_name'],
          returnGeometry: false,
        });*/

        const savedSearchesMsNames = await savedSearchesTable.current?.queryFeatures({
          where: `user_id = '${props.user?.username}'`,
          outFields: ['ms_name'],
          returnGeometry: false,
        });

        const allMsNames = [
          //...(favoritePropertiesMsNames?.features || []).map((feature) => feature.attributes.ms_name),
          ...(savedSearchesMsNames?.features || []).map((feature) => feature.attributes.ms_name),
        ];
    
        const uniqueMsNames = Array.from(new Set(allMsNames)).filter((msName) => msName !== null && msName !== '');
        //console.log(uniqueMsNames)
        const userFilterOptions = ['Shared', ...uniqueMsNames];
    
        setUserFilterOptions(userFilterOptions);
      };
    
      fetchMsNames();
    }
    }, [savedSearchesLoaded, savedHit]);

    useEffect(() => {
      if (userFilterOptions.length && !userFilterOptions.includes(userFilter)) {
        setUserFilter(userFilterOptions[0]); // or some other default value
        sessionStorage.setItem('userFilter', JSON.stringify(userFilterOptions[0]));
      }
    }, [userFilterOptions]);

  const testArray = ["Shared", "Dalton Silhan", "Brian Williams", "Taylor Kerton", "Richard Harper"]

  return (
    <div className="saved-searches">
      <Breadcrumb >
        <BreadcrumbItem className="breadcrumb">
          <Link to="/start">Start</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="page-title">
        Saved searches
      </div>
      <Layer className="list-controls">
        <Search
          className="search"
          closeButtonLabelText="Clear search input"
          id="saved-searches-search"
          labelText="Filter saved searches"
          placeholder="Filter your saved searches by name..."
          onChange={onFilterChange}
          disabled={!savedSearchesLoaded}
        />
        <div className="rh-controls">
          <Dropdown
            className="sort-by"
            id="saved-property-sort"
            label="Sort by"
            titleText="Sort by"
            items={sortOptions}
            initialSelectedItem='Recently added'
            onChange={onSortOptionChange}
            size='md'
            itemToString={(item: string) => item}
            type='inline'
          />

          <Select
            id="user-select"
            className="user-select"
            placeholder="Select Value"
            disabled={!savedSearchesLoaded}
            labelText="BDM Select"
            value={userFilter}
            onChange={(e: any) => handleUserChange(e.target.value)}
          >
            {userFilterOptions.map((branch: any) => {
              return (
                <SelectItem
                  key={branch}
                  value={branch}
                  text={branch}
                />
              );
            })}
          </Select>

          <Button
            onClick={() => { navigate("/properties") }}
            size="md"
            renderIcon={Add}
          >
            New search
          </Button>
        </div>
      </Layer>
      <div className="search-list">
        {savedSearchesLoaded && displaySavedSearches.map(search =>
          <SavedSearchListItem
            key={search.id}
            search={search}
            removeSavedSearch={removeSavedSearch}
            renameSavedSearch={renameSavedSearch}
            moveSearch={moveSearch}
          />
        )}
        {!savedSearchesLoaded &&
          <InlineLoading
            status="active"
            iconDescription="Active Loading Indicator"
            description="Loading searches..."
          />
        }
      </div>
    </div>
  );
}