import React, { useCallback, useEffect, useRef, useState } from "react";

import "./saved-properties.scss";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Property from "../../models/property";
import SavedPropertyListItem from "./saved-property-list-item/SavedPropertyListItem";
import { Link, useNavigate } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  InlineLoading,
  Layer,
  Search,
  Dropdown,
  Select,
  SelectItem
  //@ts-ignore
} from '@carbon/react';

import {
  Add,
  //@ts-ignore
} from '@carbon/icons-react';

interface iProps {
  user: __esri.PortalUser | null;
  propertyLayerUrl: string;
  favoritePropertiesTableUrl: string;
  unfavoriteProperty: (propertyId: number) => void;
  notedProperties: Set<number>;
  savedProperties: Set<number> | undefined;
  filterSavedProperties: (propertyId: any) => void;
  favoriteProperty: (propertyId: any) => void;
}

const sortOptions = ["Recently added", "Recommended", "Lot size (acres)"]; //UPDATES FROM NATALIE
export default function SavedProperties(props: iProps) {
  const navigate = useNavigate();
  const propertyLayer = useRef<__esri.FeatureLayer>();
  const favoritePropertiesTable = useRef<__esri.FeatureLayer>();

  const [propertiesLoaded, setPropertiesLoaded] = useState(false);
  const [allSavedProperties, setAllSavedProperties] = useState<any>([]);
  const [displayProperties, setDisplayProperties] = useState<Property[]>([]);
  const [sortOption, setSortOption] = useState("Recently added");
  const [filterText, setFilterText] = useState("");

  const [userFilter, setUserFilter] = useState<string>("")
  const [userFilterOptions, setUserFilterOptions] = useState<string[]>([]);
  const [savedHit, setSavedHit] = useState<number>(0);

  const microsoftName = sessionStorage.getItem('MicrosoftName')?.replace(/^"|"$/g, '');


  useEffect(() => {
    const userFilterStored = sessionStorage.getItem('userFilter')?.replace(/^"|"$/g, '');
    if (props.savedProperties && userFilter !== "") {
      setPropertiesLoaded(false)
      const abortController = new AbortController();
      if (props.user?.username/* && !favoritePropertiesTable.current && !propertyLayer.current*/) {
        favoritePropertiesTable.current = new FeatureLayer({ url: props.favoritePropertiesTableUrl });
        propertyLayer.current = new FeatureLayer({ url: props.propertyLayerUrl });

        let where = ``

        if (userFilter === "Shared") {
          where = `user_id = '${props.user?.username}'`
        }
        else if (userFilter !== "Shared") {
          where = `user_id = '${props.user?.username}' AND ms_name = '${userFilter}'`
        }

        //console.log(where)

        favoritePropertiesTable.current?.queryFeatures({
          where: where,
          returnGeometry: false,
          outFields: ['property_id', 'created_on', 'ms_name', 'property_title'],
          orderByFields: ['created_on DESC']
        }, { signal: abortController.signal }).then(favoriteProperties => {
          if (favoriteProperties.features.length > 0) {
            const propertyIds: number[] = [];
            const propertySavedOnDict: { [key: number]: number } = {};
            const propertyTitleDict: { [key: number]: string } = {}; // New dictionary for property titles
            //console.log(favoriteProperties)

            favoriteProperties.features.forEach(feature => {
              const propertyId = feature.attributes['property_id'];
              propertyIds.push(propertyId);
              propertySavedOnDict[propertyId] = feature.attributes['created_on'];
              propertyTitleDict[propertyId] = feature.attributes['property_title']; // Store property title
            });

            propertyLayer.current?.queryFeatures({
              where: `PropertyID IN (${propertyIds.join(',')})`,
              outFields: [
                "PropertyID",
                "Property_Name",
                "Property_Address",
                "City",
                "State",
                "Zip",
                "Property_Type_Main",
                "Land_Area__AC_",
                "RBA",
                "Rank",
                "Property_Manager_Name",
                "Property_Manager_Contact",
                "Property_Manager_Phone",
                "Leasing_Company_Name",
                "Leasing_Company_Contact",
                "Leasing_Company_Phone",
                "Owner_Name",
                "Owner_Contact",
                "Owner_Phone",
                "Amenities",
                "Number_Of_Parking_Spaces",
                "Secondary_Type",
                "GoogleStreetView",
                "GoogleSearch",
                "GoogleMaps",
                "BingStreetView",
                "Closest_Branch",
                "Customer",
                "ToBreak",
                "OpCo",
                "OBJECTID",
                "Submarket_Cluster",
                "Latitude",
                "Longitude"
              ],
              returnGeometry: false,
            }, { signal: abortController.signal }).then(response => {
              const _properties = response.features.map(feature => {
                const atts = feature.attributes;
                return {
                  id: atts.PropertyID,
                  name: atts.Property_Name,
                  address: atts.Property_Address,
                  city: atts.City,
                  state: atts.State,
                  zip: atts.Zip,
                  type: atts.Property_Type_Main,
                  acres: atts.Land_Area__AC_,
                  buildingArea: atts.RBA,
                  rank: atts.Rank,
                  propertyManagerName: atts.Property_Manager_Name,
                  propertyManagerContact: atts.Property_Manager_Contact,
                  propertyManagerPhone: atts.Property_Manager_Phone,
                  leasingCompanyName: atts.Leasing_Company_Name,
                  leasingCompanyContact: atts.Leasing_Company_Contact,
                  leasingCompanyPhone: atts.Leasing_Company_Phone,
                  ownerName: atts.Owner_Name,
                  ownerContact: atts.Owner_Contact,
                  ownerPhone: atts.Owner_Phone,
                  amenities: atts.Amenities,
                  parkingSpaces: atts.Number_Of_Parking_Spaces,
                  type_secondary: atts.Secondary_Type,
                  googleStreetView: atts.GoogleStreetView,
                  googleSearch: atts.GoogleSearch,
                  googleMaps: atts.GoogleMaps,
                  bingStreetView: atts.BingStreetView,
                  closestBranch: atts.Closest_Branch,
                  customer: atts.Customer,
                  driveTime: atts.ToBreak,
                  opco: atts.Sales_Company,
                  objectId: atts.OBJECTID,
                  submarketCluster: atts.Submarket_Cluster,
                  latitude: atts.Latitude,
                  longitude: atts.Longitude,
                  favoriteTimestamp: propertySavedOnDict[atts.PropertyID],
                  propertyTitle: propertyTitleDict[atts.PropertyID] // Add property title here
                }
              });
              setAllSavedProperties(_properties);
              props.filterSavedProperties(new Set(propertyIds));
              setPropertiesLoaded(true);
            }).catch((reason) => {
              console.log(reason);
              if (reason.name === "AbortError") return;
              setAllSavedProperties([]);
              setPropertiesLoaded(true);
            });
          }

          return () => {
            abortController.abort();
          }
        })
      }
    }
  }, [userFilter]);

  const filterProperties = () => {
    if (allSavedProperties.length > 0) {
      let _properties = [...allSavedProperties];
      if (filterText) {
        _properties = _properties.filter(property =>
          property.name?.toLowerCase().includes(filterText)
          || property.address?.toLocaleLowerCase().includes(filterText)
          || property.propertyTitle?.toLocaleLowerCase().includes(filterText)
        );
      }

      if (sortOption === "Recently added") {
        _properties.sort((a, b) => (b.favoriteTimestamp || 0) - (a.favoriteTimestamp || 0));
      } else if (sortOption === "Recommended") {
        _properties.sort((a, b) => (b.rank || 0) - (a.rank || 0));
      } else if (sortOption === "Lot size (acres)") { //UPDATES FROM NATALIE
        _properties.sort((a, b) => (b.acres || 0) - (a.acres || 0));
      }

      setDisplayProperties(_properties)
    } else {
      setDisplayProperties([]);
    }
  }
  useEffect(filterProperties, [sortOption, filterText, allSavedProperties]);

  const removeProperty = useCallback((propertyId: number) => {
    const favProps = allSavedProperties.filter((property: any) => property.id !== propertyId);
    setAllSavedProperties(favProps);
    props.unfavoriteProperty(propertyId);
    setTimeout(() => {
      setSavedHit(prevCount => prevCount ? prevCount + 1 : 1);
    }, 2000);
  }, [props, allSavedProperties]);

  const onFilterChange = (evt: any) => {
    setFilterText(evt.target.value.toLowerCase());
  }

  const onSortOptionChange = (evt: any) => {
    setSortOption(evt.selectedItem);
  }

  const renameSavedProperty = (property_id: number, newName: string) => {
    const faveProperties = favoritePropertiesTable.current
    faveProperties!.queryFeatures({
      where: `property_id = ${property_id} AND ms_name = '${microsoftName}'`,
      outFields: ["*"],
    }).then((result) => {
      if (result.features.length > 0) {
        //const credits = result.features.map(feature => feature.attributes.Credits);
        // Update attributes
        //console.log(credits)
        const featureToUpdate = result.features[0];
        const currentValue = featureToUpdate.attributes.property_title;
        const newValue = newName;
        /*if (newValue == 0) {
          setCredits(false)
        }*/

        // Apply the update
        featureToUpdate.attributes.property_title = newValue;

        // Apply the update
        faveProperties!.applyEdits({
          updateFeatures: [featureToUpdate],
        }).then((editsResult) => {
          if (editsResult.updateFeatureResults.length > 0) {
            console.log("Feature updated successfully");
          } else {
            console.error("Error updating feature");
          }
        });
      } else {
        console.error("Feature not found");
      }
    });
  }

  useEffect(() => {
    const userFilterStored = sessionStorage.getItem('userFilter')?.replace(/^"|"$/g, '');
    if (userFilterStored) {
      setUserFilter(userFilterStored)
    }
  }, []);

  const handleUserChange = (value: string) => {

    setUserFilter(value)
    sessionStorage.setItem('userFilter', JSON.stringify(value));

  }

  const favoriteProperty = (propertyId: number) => {
    if (props.favoriteProperty!) {
      props.favoriteProperty(propertyId);
      setTimeout(() => {
        setSavedHit(prevCount => prevCount ? prevCount + 1 : 1);
      }, 2000);
    }
  }

  useEffect(() => {
    if (favoritePropertiesTable.current) {
      //console.log("fetching options")
      const fetchMsNames = async () => {
        const favoritePropertiesMsNames = await favoritePropertiesTable.current?.queryFeatures({
          where: `user_id = '${props.user?.username}'`,
          outFields: ['ms_name'],
          returnGeometry: false,
        });

        /*const savedSearchesMsNames = await savedSearchesTable.current?.queryFeatures({
          where: `user_id = '${props.user?.username}'`,
          outFields: ['ms_name'],
          returnGeometry: false,
        });*/

        const allMsNames = [
          ...(favoritePropertiesMsNames?.features || []).map((feature) => feature.attributes.ms_name),
          //...(savedSearchesMsNames?.features || []).map((feature) => feature.attributes.ms_name),
        ];

        const uniqueMsNames = Array.from(new Set(allMsNames)).filter((msName) => msName !== null && msName !== '');
        //console.log(uniqueMsNames)
        const userFilterOptions = ['Shared', ...uniqueMsNames];

        setUserFilterOptions(userFilterOptions);
      };

      fetchMsNames();
    }
  }, [propertiesLoaded, savedHit]);

  useEffect(() => {
    if (userFilterOptions.length && !userFilterOptions.includes(userFilter)) {
      setUserFilter(userFilterOptions[0]); // or some other default value
      sessionStorage.setItem('userFilter', JSON.stringify(userFilterOptions[0]));
    }
  }, [userFilterOptions]);

  const testArray = ["Shared", "Dalton Silhan", "Brian Williams", "Taylor Kerton", "Richard Harper"]

  return (
    <div className="saved-properties">
      <Breadcrumb >
        <BreadcrumbItem className="breadcrumb">
          <Link to="/start">Start</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="page-title">
        Saved properties
      </div>
      <Layer className="list-controls">
        <Search
          className="search"
          closeButtonLabelText="Clear search input"
          id="saved-properties-search"
          labelText="Filter saved properties"
          placeholder="Filter your saved properties..."
          onChange={onFilterChange}
          disabled={!propertiesLoaded}
        />
        <div className="rh-controls">
          <Dropdown
            className="sort-by"
            id="saved-property-sort"
            label="Sort by"
            titleText="Sort by"
            items={sortOptions}
            initialSelectedItem='Recently added'
            onChange={onSortOptionChange}
            size='md'
            itemToString={(item: string) => item}
            type='inline'
          />
          <Select
            id="user-select"
            className="user-select"
            placeholder="Select Value"
            disabled={!propertiesLoaded}
            labelText="BDM Select"
            value={userFilter}
            onChange={(e: any) => handleUserChange(e.target.value)}
          >
            {userFilterOptions.map((branch: any) => {
              return (
                <SelectItem
                  key={branch}
                  value={branch}
                  text={branch}
                />
              );
            })}
          </Select>
          <Button
            onClick={() => { navigate("/properties") }}
            size="md"
            renderIcon={Add}
          >
            New search
          </Button>
        </div>
      </Layer>
      <div className="property-list">
        {propertiesLoaded && displayProperties.map((property, index) =>
          <SavedPropertyListItem
            key={index}
            property={property}
            removeProperty={removeProperty}
            renameProperty={renameSavedProperty}
            hasNote={props.notedProperties.has(property.id)}
            favoriteProperty={favoriteProperty}
          />
        )}
        {!propertiesLoaded &&
          <InlineLoading
            status="active"
            iconDescription="Active Loading Indicator"
            description="Loading saved properties..."
          />
        }
      </div>
    </div>
  );
}